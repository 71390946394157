
import { Flex, Image, Text } from '@chakra-ui/react';
import spring from '../../../img/DNM01.png';
import summer from '../../../img/DNM02.png';
import autumn from '../../../img/DNM03.png';
import winter from '../../../img/DNM04.png';

function Season({ handleClick, selectedSeason }) {
  const buttonStyle = index => ({
    border: selectedSeason === index ? '5px solid white' : 'none',
    borderRadius: selectedSeason === index ? '18px' : 'none',
    boxShadow: selectedSeason === index ? '0 0 10px rgba(255, 255, 255, 0.5)' : 'none',
    transition: 'all 0.3s ease-in-out',
  });

  const textStyle = index => ({
    color: selectedSeason === index ? 'orange' : 'white',
  });

  return (
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      flexDirection={'column'}
      w={'206px'}
      h={'460px'}
      transition={'all 0.3s ease-in-out'}
    >
      <Flex
        as={'button'}
        justifyContent={'space-between'}
        alignItems={'center'}
        w={'100%'}
        h={'100px'}
        onClick={() => handleClick(0)}
        transition={'all 0.3s ease-in-out'}
      >
        <Image
          transition={'all 0.3s ease-in-out'}
          style={buttonStyle(0)}
          borderRadius={'4px'}
          h={'100px'}
          w={'100px'}
          src={spring}
        />
        <Text
          style={textStyle(0)}
          color={'white'}
          lineHeight={'30px'}
          letterSpacing={'5%'}
          fontSize={'16px'}
          fontWeight={'700'}
          fontFamily={'Open Sans'}
          transition={'all 0.3s ease-in-out'}
        >
          SPRING
        </Text>
      </Flex>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        w={'100%'}
        h={'100px'}
        as={'button'}
        onClick={() => handleClick(1)}
        transition={'all 0.3s ease-in-out'}
      >
        <Image
          transition={'all 0.3s ease-in-out'}
          style={buttonStyle(1)}
          borderRadius={'4px'}
          h={'100px'}
          w={'100px'}
          src={summer}
        />
        <Text
          style={textStyle(1)}
          color={'white'}
          lineHeight={'30px'}
          letterSpacing={'5%'}
          fontSize={'16px'}
          fontWeight={'700'}
          fontFamily={'Open Sans'}
          transition={'all 0.3s ease-in-out'}
        >
          SUMMER
        </Text>
      </Flex>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        w={'100%'}
        h={'100px'}
        as={'button'}
        onClick={() => handleClick(2)}
        transition={'all 0.3s ease-in-out'}
      >
        <Image
          transition={'all 0.3s ease-in-out'}
          style={buttonStyle(2)}
          borderRadius={'4px'}
          h={'100px'}
          w={'100px'}
          src={autumn}
        />
        <Text
          style={textStyle(2)}
          color={'white'}
          lineHeight={'30px'}
          letterSpacing={'5%'}
          fontSize={'16px'}
          fontWeight={'700'}
          fontFamily={'Open Sans'}
          transition={'all 0.3s ease-in-out'}
        >
          AUTUMN
        </Text>
      </Flex>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        w={'100%'}
        h={'100px'}
        as={'button'}
        onClick={() => handleClick(3)}
        transition={'all 0.3s ease-in-out'}
      >
        <Image
          transition={'all 0.3s ease-in-out'}
          style={buttonStyle(3)}
          borderRadius={'4px'}
          h={'100px'}
          w={'100px'}
          src={winter}
        />
        <Text
          style={textStyle(3)}
          color={'white'}
          lineHeight={'30px'}
          letterSpacing={'5%'}
          fontSize={'16px'}
          fontWeight={'700'}
          fontFamily={'Open Sans'}
          transition={'all 0.3s ease-in-out'}
        >
          WINTER
        </Text>
      </Flex>
    </Flex>
  );
}

export default Season;
