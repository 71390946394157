import { Center, Flex, Text, Image, Box, Spacer, useEditable } from '@chakra-ui/react';
import React from 'react';
import Header from './headerAB';
import Footer from './footerAB';
import line from '../../../img/lineAB.png';
import avt1 from '../../../img/avt1.png';
import avt2 from '../../../img/avt2.png';
import avt3 from '../../../img/avt3.png';
import avt4 from '../../../img/avt4.png';
import avt5 from '../../../img/avt5.png';
import { useEffect } from 'react';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0); // Cuộn lên đầu trang khi component được tạo
  }, []); 
  return (
    <>
      <Header/>
      <Center
        w={'100%'}
        minH={'900px'}
        bg={'#FFF6E4'}
        flexDirection={'column'}
        p={4}
      >
        <Flex
          w={{ base: '100%', md: '1100px' }}
          h={'auto'}
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'stretch' }}
        >
          <Box
            w={{ base: '100%', md: '332px' }}
            textAlign={{ base: 'center', md: 'left' }}
          >
            <Text
              fontFamily={'Rowdies'}
              fontSize={{ base: '36px', md: '60px' }}
              fontWeight={'700'}
              lineHeight={'75px'}
              textColor={'#1C375D'}
            >
              ABOUT US
            </Text>
          </Box>
          <Spacer display={{ base: 'none', md: 'block' }} />
          <Image src={line} h={'104px'} display={{ base: 'none', md: 'block' }} />
          <Spacer display={{ base: 'none', md: 'block' }} />
          <Flex
            w={{ base: '100%', md: '658px' }}
            textAlign={{ base: 'center', md: 'left' }}
            flexDirection={'column'}
          >
            <Box
              fontFamily={'Open sans'}
              fontSize={{ base: '16px', md: '18px' }}
              fontWeight={'600'}
              lineHeight={'24px'}
              textColor={'#1C375D'}
              as='b'
            >
              Our goal is to develop an open-world game with boundless possibilities that
            </Box>
            <Box
              fontFamily={'Open sans'}
              fontSize={{ base: '16px', md: '18px' }}
              fontWeight={'600'}
              lineHeight={'24px'}
              textColor={'#1C375D'}
              as='b'
            >
              is free of cost and, above all, enjoyable. In our virtual world of D'islands,
            </Box>
            <Box
              fontFamily={'Open sans'}
              fontSize={{ base: '16px', md: '18px' }}
              fontWeight={'600'}
              lineHeight={'24px'}
              textColor={'#1C375D'}
              as='b'
            >
              people from every corner of the globe can come together to explore,
            </Box>
            <Box
              fontFamily={'Open sans'}
              fontSize={{ base: '16px', md: '18px' }}
              fontWeight={'600'}
              lineHeight={'24px'}
              textColor={'#1C375D'}
              as='b'
            >
              embark on adventures, and socialize without any barriers or limitations.
            </Box>
          </Flex>
        </Flex>
        <Flex
          w={'100%'}
          h={'auto'}
          mt={'70px'}
          flexWrap={'wrap'}
          justifyContent={'center'}
        >
          {[avt1, avt2, avt3, avt4, avt5].map((avt, index) => (
            <Box
              key={index}
              w={{ base: '100%', md: '20%' }}
              h={'auto'}
              textAlign={'center'}
            >
              <Image src={avt} w={'100%'} />
              <Flex flexDirection={'column'} w={'100%'} h={'104px'} mt={'20px'}>
                <Box fontFamily={'Rowdies'} fontSize={'24px'} fontWeight={'700'} lineHeight={'26px'} textColor={'#1C375D'}>
                  {['ANDERSON', 'JOHN', 'RICK', 'KAYD', 'JUSTIN'][index]}
                </Box>
                <Box as='b' fontFamily={'Open sans'} fontSize={'18px'} fontWeight={'600'} lineHeight={'26px'} mt={'10px'}>
                  {['Product Designer', 'CEO', 'CTO', 'CMO', 'BRM'][index]}
                </Box>
              </Flex>
            </Box>
          ))}
        </Flex>
      </Center>
      <Footer/>
    </>
  );
}

export default About;
