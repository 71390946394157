import { useLocation } from 'react-router-dom';
import React, { useRef, useEffect } from 'react';
import Header from "./Header/header";
import Footer from "./Footer/Footer";

function LayoutPC({ children}) {
    const RefROADMAP = useRef(null);
    const RefWork = useRef(null);
    const RefBanner = useRef(null);
    const handleScroll = (ref) => {
        if (ref.current) {
            const yOffset = -100; // Offset from the top
            const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

      // Lấy thông tin về query parameter từ URL
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const scrollToRefName = urlParams.get('scrollTo');

  // Thực hiện cuộn khi component được mount hoặc khi query parameter thay đổi
  useEffect(() => {
    if (scrollToRefName) {
      const refToScroll = {
        RefWork: RefWork,
        RefROADMAP: RefROADMAP,
      }[scrollToRefName];

      if (refToScroll && refToScroll.current) {
        handleScroll(refToScroll);
      }
    }
  }, [scrollToRefName]);
    
    return (
        <>
            <Header onButtonClick={handleScroll} RefBanner={RefBanner} RefWork={RefWork} RefROADMAP={RefROADMAP}  />
            <main>
                <section ref={RefBanner}>
                    {children[0]}
                </section>
                <section ref={RefWork}>
                    {children[1]}
                </section>
                {children[2]}
                {children[3]}
                {children[4]}
                <section ref={RefROADMAP}>
                    {children[5]}
                </section>
                {children[6]}
                {children[7]}
            </main>
            <Footer onButtonClick={handleScroll} RefBanner={RefBanner} RefROADMAP={RefROADMAP} RefWork={RefWork} />
        </>
    );
}

export default LayoutPC;
