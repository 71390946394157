import logoWeb from '../../../img/logo2.png';
import { Flex, Center, Image, Button, Box } from '@chakra-ui/react';
import React from 'react';
import './Header.css';
import styled from 'styled-components';
import image from '../../../img/HD-mb.png';
import { HamburgerIcon } from '@chakra-ui/icons';
import { CloseIcon } from '@chakra-ui/icons';
import { ScaleFade } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import button from '../../../img/connect.png';
import { Link, useNavigate } from 'react-router-dom';

const ButtonCenter = styled.button`
width: 25%;
background-color: unset;
color: white;
font-size: 12px;
margin: 5px;


&:hover {
  background-color: unset;
  color: #ee6c49;
}
`;

function Header({ onButtonClick, RefBanner, RefWork, RefROADMAP,RefAbout }) {
  const navigate = useNavigate();

  const handleButtonClick = (refName) => {
    if (refName === 'about') {
      navigate('/about'); // Điều hướng đến /about khi click "ABOUT US"
    } else {
      onButtonClick(refName); // Xử lý các nút khác như cũ
    }
  };

  const { isOpen, onToggle } = useDisclosure()
  
  const handleClick = () => {
    window.open("https://docs.dislands.com/", "_blank", "noopener noreferrer");
  };

  const HandleonToggle = () => {
    onToggle(!isOpen);
  };
  return (
    <Box zIndex={1000} position={'fixed'} w={'100%'} h={'200px'}>
      <Flex
        w={'100%'}
        h={'60px'}
        bg={'#FFF6E4'}
        justifyContent={'space-between'}
        alignItems={'center'}
        boxShadow='dark-lg'
      >
        <Image
          ml={'36px'}
          cursor={'pointer'}
          onClick={() => onButtonClick(RefBanner)}
          className="logoWeb"
          w={'64.8px'}
          src={logoWeb}
        />
        <HamburgerIcon  as={isOpen ? CloseIcon : HamburgerIcon}
        mr={'40px'} w={'17px'} onClick={HandleonToggle}/>
      </Flex>
      <ScaleFade initialScale={0.9} in={isOpen}>

        <Box
        transition={'ease-in-out 0.3s all'}
        id='header-hidden'
        display='flex'
        w={'100%'}
        h={'108px'}
        bg={'#1C375D'}
        bgSize={'100% 101%'}
        flexDirection={'column'}
        justifyContent={'space-evenly'}
        position={'hidden'}
        
      >
        <Image h={'100px'} w={'100%'} src={image} zIndex={1} position={'absolute'} blendMode={'multiply'}/>
        <Flex ml={'15px'} zIndex={2}>
          <ButtonCenter onClick={() => onButtonClick(RefWork)}>
            FEATURES
          </ButtonCenter>
          <ButtonCenter onClick={() => onButtonClick(RefROADMAP)}>
            ROADMAP
          </ButtonCenter>

          <ButtonCenter onClick={handleClick}>DOCUMENT</ButtonCenter>
          <ButtonCenter onClick={() => handleButtonClick('about')}>ABOUT US</ButtonCenter>
        </Flex>
        </Box>  
      </ScaleFade>  
      </Box>
  );
}

export default Header;
