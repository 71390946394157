import { Flex, Text, Image } from "@chakra-ui/react";
import muiten from '../../../img/tenMap.png'

function Timeline () {

return(
    <Flex justifyContent={'space-between'} alignItems={'center'} w={'1216px'} h={'30px'}>
        <Text color={'#1C376D'} lineHeight={'30px'} fontSize={'30px'} fontWeight={'700'}>Q1•2024</Text>
        <Image h={'20px'} w={'34.15px'} src={muiten}/>
        <Text color={'#1C376D'} lineHeight={'30px'} fontSize={'30px'} fontWeight={'700'}>Q2•2024</Text>
        <Image h={'20px'} w={'34.15px'} src={muiten}/>
        <Text color={'#1C376D'} lineHeight={'30px'} fontSize={'30px'} fontWeight={'700'}>Q3•2024</Text>
        <Image h={'20px'} w={'34.15px'} src={muiten}/>
        <Text color={'#1C376D'} lineHeight={'30px'} fontSize={'30px'} fontWeight={'700'}>Q4•2024</Text>
        <Image h={'20px'} w={'34.15px'} src={muiten}/>
        <Text color={'#1C376D'} lineHeight={'30px'} fontSize={'30px'} fontWeight={'700'}>Q1-Q2•2025</Text>
    </Flex>
);

}

export default Timeline