import { Box, Image, Center, Flex } from '@chakra-ui/react';
import React from 'react';
import ContainerRoadMap from './ContainerRoadMap';
import titleRM from '../../../img/TitleRM.png';
import timeline from '../../../img/timelineRM.png'
import '../../Mobile/Roadmap/RoadMap.css';

function Roadmap({ RefROADMAP }) {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      ref={RefROADMAP}
      w={'99%'}
      h={'1575px'}
      bg={'#FFF6E4'}
    >
      <Image src={titleRM} w={'100%'} mt={'50px'}/>
      <Box className='content-container'  w={'100%'}>
      <Flex
        mt={'60px'}
        w={'100%'}
        h={'1293px'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
      >
        <Image src={timeline} w={'127px'}/>
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          w={'228px'}
          h={'1321px'}
          flexDirection={'column'}
        >
          <ContainerRoadMap
            text1={'Visualize Game Art'}
            text2={'Testnet World Launch'}
            text3={'Expanding Team'}
            text4={'Grant Application'}
          />
          <ContainerRoadMap
            text1={'Marketing Campaign'}
            text2={'Community Build'}
            text3={'Incubation participant'}
            text4={"D'islands D-App Build"}
            text5={'Rebranding'}
          />
          <ContainerRoadMap
            text1={'Legendary PFP Genesis Event'}
            text2={'TGE'}
            text3={'Alpha Test First FOCG D’islands Game'}
            text4={'Beta Test First FOCG D’islands Game'}
          />
          <ContainerRoadMap
            text1={'D’islands First Game Launch'}
            text2={'Islands Genesis Event'}
          />
          <ContainerRoadMap
            text1={'Launch Second FOCG Game'}
            text2={'Launch Islands Farm'}
            text3={'-------------------------------'}
            text4={'Blizzard'}
          />
        </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default Roadmap;
