import { Box, Image, Center, Flex } from '@chakra-ui/react';
import React from 'react';
import RM3 from '../../../img/RM3.png';
import Heading from '../Heading';
import Timeline from './Timeline';
import ContainerRoadMap from './ContainerRoadMap';
import '../ROADMAP/RoadMap.css';

function Roadmap({ RefROADMAP }) {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      ref={RefROADMAP}
      w={'100%'}
      bg={'#FFF6E4'}
    >
      <Heading text={'ROADMAP'} />
      <Box className='content-container'  w={'100%'} overflowX={'scroll'}>
      <Flex
        mt={'60px'}
        flexDirection={'column'}
        w={'100%'}
        bgImage={RM3}
        h={'436px'}
        alignItems={'center'}
        justifyContent={'space-evenly'}
      >
        <Timeline />
        <Flex
          justifyContent={'space-between'}
          alignItems={'center'}
          w={'1280.14px'}
          h={'236.28px'}
        >
          <ContainerRoadMap
            text1={'Visualize Game Art'}
            text2={'Testnet World Launch'}
            text3={'Expanding Team'}
            text4={'Grant Application'}
          />
          <ContainerRoadMap
            text1={'Marketing Campaign'}
            text2={'Community Build'}
            text3={'Incubation participant'}
            text4={"D'islands D-App Build"}
            text5={'Rebranding'}
          />
          <ContainerRoadMap
            text1={'Legendary PFP Genesis Event'}
            text2={'TGE'}
            text3={'Alpha Test First FOCG D’islands Game'}
            text4={'Beta Test First FOCG D’islands Game'}
          />
          <ContainerRoadMap
            text1={'D’islands First Game Launch'}
            text2={'Islands Genesis Event'}
          />
          <ContainerRoadMap
            text1={'Launch Second FOCG Game'}
            text2={'Launch Islands Farm'}
            text3={'-------------------------------'}
            text4={'Blizzard'}
          />
        </Flex>
      </Flex>
      </Box>
    </Box>
  );
}

export default Roadmap;
