import { Box, Center, Flex, Image,Text } from '@chakra-ui/react';
import PN1 from '../../img/PN1.png';
import PN2 from '../../img/PN2.png';
import PN3 from '../../img/PN3.png';
import line2 from '../../img/line2.png';
import '../Mobile/GameList/GameList.css'

function Partner() {
  return (
    <Flex
      justifyContent={'space-between'}
      flexDirection={'column'}
      w={'99%'}
      h={'240px'}
      bg={'#FFF6E4'}
    >
      <Flex
        justifyContent={'space-between'}
        flexDirection={'column'}
        w={'90%'}
        h={'220px'}
        ml={'20px'}
      >
        <Box
          w={'193px'}
          textAlign={'top'}
          ml={'10px'}
        >
          <Text 
          fontSize={'30px'}
          textColor={'#1C375D'}
          fontFamily={'Rowdies'}
          as='b'> PARTNER & INCUBATOR</Text>
        </Box>
        <Flex
          mt={'20px'}
          display={'flex'}
          className="content-container"
          overflowX={'scroll'}
          overflowY={'hidden'}
          justifyContent={'start'}
          position={'relative'}
          transition={'all 0.3s ease'}
          flexDirection={'row'}
          boxSize={'100%'}>
          <Image mr={'21px'} ml={'10px'} h={'97px'} src={PN1} />
          <Image mx={'21px'} h={'97px'} src={PN2} />
          <Image mx={'21px'} h={'97px'} src={PN3} />
        </Flex>
      </Flex>
      <Image ml={'5%'} src={line2} w={'90%'} />
    </Flex>
  );
}

export default Partner;
