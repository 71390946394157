import { Flex, Center, Image, Text } from '@chakra-ui/react';

function SeasonLand({ handleClick, seasons, currentSeason }) {
  return (
    <Flex
      justifyContent="space-between"
      h="330px"
      alignItems="center"
      w="339px"
      flexDirection="column"
      as="button"
      onClick={handleClick}
      cursor="pointer"
    >
      <Center>
        <Image src={seasons[currentSeason].image} w="339px" h="330px" />
      </Center>
      <Text
        bgColor="rgba(10, 16, 30, 1)"
        color="white"
        border="1px solid rgba(255, 246, 228, 1)"
        borderRadius="10px"
        h="28px"
        w="140px"
        textAlign="center"
        lineHeight="30px"
        fontFamily={'Open sans'}
        fontSize={'12px'}
        letterSpacing={'5%'}
        as='b'
      >
        {seasons[currentSeason].text}
      </Text>
    </Flex>
  );
}

export default SeasonLand
