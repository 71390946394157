import bg from '../../../img/bgW.png';
import line2 from '../../../img/line2.png';
import line1 from '../../../img/line1.png';
import HT1 from '../../../img/htt1.png';
import HT2 from '../../../img/htt2.png';
import HT3 from '../../../img/htt3.png';
import { useState } from 'react';
import { Flex, Center, Image, Box, Text as ChakraText } from '@chakra-ui/react';
import styled from 'styled-components';
import EmblaCarousel from './Embla.Carousel';

const SLIDE_COUNT = 3;
const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

const StyledText = styled(ChakraText)`
  font-size: 25px;
  color: #1c375d;
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  opacity: ${({ isActive }) => (isActive ? '100%' : '40%')};
`;

function Work({
  RefGameList,
  RefDynamicWorld,
  RefTicket,
  onButtonClick,
}) {
  const [currentSlide, setCurrentSlide] = useState(0); // Level Up is the initial active

  const handleClickGameList = () => {
    setCurrentSlide(2);
    onButtonClick(RefGameList);
  };

  const handleClickDynamicWorld = () => {
    setCurrentSlide(0);
    onButtonClick(RefDynamicWorld);
  };

  const handleClickTicket = () => {
    setCurrentSlide(1);
    onButtonClick(RefTicket);
  };

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Box
      position="relative"
      alignItems="center"
      display="flex"
      w="99%"
      h="520px"
      bgRepeat="cover"
      bg={'#FFF6E4'}
    >
      <Image src={bg} blendMode={'multiply'} w={'100%'} position={'absolute'} mb={'280px'}/>
      <Flex flexDirection="column" w="100%">
        <Image src={line1} />
        <Flex
          h="83px"
          w="100%"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <StyledText 
            onClick={handleClickGameList} 
            isActive={currentSlide === 2}
          >
            PLAY
          </StyledText>
          <StyledText 
            onClick={handleClickDynamicWorld} 
            isActive={currentSlide === 0}
          >
            LEVEL UP
          </StyledText>
          <StyledText 
            onClick={handleClickTicket} 
            isActive={currentSlide === 1}
          >
            EARN
          </StyledText>
        </Flex>
        <Image src={line2} />
        <Center mt={'20px'}>
          <EmblaCarousel slides={SLIDES} options={{ loop: true }} onSlideChange={handleSlideChange}/>
        </Center>
      </Flex>
    </Box>
  );
}

export default Work;
