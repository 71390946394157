import React, { useRef } from 'react';
import bg from '../../../img/ft2.png';
import HT1 from '../../../img/HT1.png';
import HT2 from '../../../img/HT2.png';
import HT3 from '../../../img/HT3.png';
import { Text, Flex, Center, Image, Box } from '@chakra-ui/react';
import line from '../../../img/Scratch line-X.png';
import muiten from '../../../img/Work_Tab.png';
import BoxContainer from './BoxContainer';
import '../Work/Work.css';

function Work({
  onButtonClick,
  RefGameList,
  RefDynamicWorld,
  RefTicket,
}) {
  const handleClickGameList = () => {
    onButtonClick(RefGameList);
  };

  const handleClickDynamicWorld = () => {
    onButtonClick(RefDynamicWorld);
  };

  const handleClickTicket = () => {
    onButtonClick(RefTicket);
  };

  return (
    <Center className="work">
      <Flex
        py={'5%'}
        w={'100%'}
        bgSize="100% 101%"
        bgImage={bg}
        bgRepeat="no-repeat"
        flexDirection={'column'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        blendMode={'multiply'}
      >
        <Center>
          <Flex alignItems={'center'} w={'100%'} h={'83px'}>
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              flexDirection={'column'}
              as={'button'}
              width={'30%'}
              h={'100%'}
              onClick={handleClickGameList}
              borderLeft={'1px solid #1C375D'}
            >
              <Image src={line} />
              <Text color={'#1C375D'} fontSize={{ sm: '30px', md: '40px' }} fontWeight={'700'}>
                PLAY
              </Text>
              <Image src={line} />
            </Flex>
            <Image w={{ sm: '40px', md: '70px' }} h={{ sm: '20px', md: '41px' }} src={muiten} />
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              flexDirection={'column'}
              as={'button'}
              width={'30%'}
              h={'100%'}
              onClick={handleClickDynamicWorld}
            >
              <Image src={line} />
              <Text color={'#1C375D'} fontSize={{ sm: '30px', md: '40px' }} fontWeight={'700'}>
                LEVEL UP
              </Text>
              <Image src={line} />
            </Flex>
            <Image w={{ sm: '40px', md: '70px' }} h={{ sm: '20px', md: '41px' }} src={muiten} />
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              flexDirection={'column'}
              as={'button'}
              width={'30%'}
              h={'100%'}
              onClick={handleClickTicket}
              borderRight={'1px solid #1C375D'}
            >
              <Image src={line} />
              <Text color={'#1C375D'} fontSize={{ sm: '30px', md: '40px' }} fontWeight={'700'}>
                EARN
              </Text>
              <Image src={line} />
            </Flex>
          </Flex>
        </Center>
        <Flex
          w={{ sm: '100%', xl: '85%' }}
          mt={'3%'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
        >
          <BoxContainer
            imagePath={HT1}
            text={'ENDLESS ACTIVITIES'}
          ></BoxContainer>
          <BoxContainer
            height={'100%'}
            imagePath={HT2}
            text={'DYNAMIC METAVERSE'}
          ></BoxContainer>
          <BoxContainer
            imagePath={HT3}
            text={'TICKET MECHANISM'}
          ></BoxContainer>
        </Flex>
      </Flex>
    </Center>
  );
}

export default Work;
