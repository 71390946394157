import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Flex,
  Image,
  Button,
  Center,
} from '@chakra-ui/react';
import PN1 from '../../img/PN1.png';
import PN2 from '../../img/PN2.png';
import PN3 from '../../img/PN3.png';
import line2 from '../../img/line2.png';
import '../PC/GameList/GameList.css';
import '../PC/GameList/GameList.css';

function Partner() {
  return (
    <Center bg={'#FFF6E4'} >
      <Box className="content-container" overflowX={'scroll'}>
      <Flex w={'1280px'} h={''} flexDirection={'column'}>
        <Flex
          my={'100px'}
          w={'1280px'}
          h={'150px'}
          justifyContent={'space-between'}
        >
          <Box
            w={'257px'}
            h={'150px'}
            fontSize={'40px'}
            fontWeight={'700'}
            lineHeight={'49.68px'}
            textColor={'#1C375D'}
            fontFamily={'Rowdies'}
          >
            PARTNER & INCUBATOR
          </Box>

          <Image w={'287.4px'} h={'150px'} src={PN1} ml={'70px'} />
          <Image w={'287.4px'} h={'150px'} src={PN2} />
          <Image w={'287.4px'} h={'150px'} src={PN3} />
        </Flex>
        <Image h={'1px'} src={line2} w={'100%'} />
      </Flex>
      </Box>
    </Center>
  );
}

export default Partner;
