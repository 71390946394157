import { Box, Flex, Image, Text, calc } from '@chakra-ui/react';
import React from 'react';
import top from '../../../img/IntersectContainer.png';
import bg from '../../../img/bgContainer.png';
import blend from '../../../img/Blend layer.png';
import frame from '../../../img/Frame 2.png';

const BoxContainer = ({ imagePath, text, height }) => {
  return (
    <Flex
      justifyContent={'space-between'}
      flexDirection={'column'}
      // w={'381.62px'}
      w={'280px'}

    >
      <Box position={'relative'} h={height}>
        <Image w={'100%'} height={'101%'} position={'absolute'} src={frame} />
        <Image
          bgImage={blend}
          shadow={'17px 21px 0px 0px #0000001A'}
          w={'100%'}
          h={'100%'}
          src={imagePath}
        />
      </Box>
      <Flex
        mt={'12px'}
        justifyContent={'center'}
        alignItems={'center'}
        bgImage={bg}
        bgSize={'100% 101%'}
        color={'#fff'}
        fontWeight={'700'}
        fontSize='16px'
        lineHeight={'26px'}
        textAlign={'center'}
        position={'relative'}
        w={'100%'}
        h='49px'  
      >
        <Image position={'absolute'} right={'0'} top={'0'} src={top} />
        {text}
      </Flex>
    </Flex>
  );
};

export default BoxContainer;
