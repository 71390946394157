
import { Flex, Text, Image, Center } from "@chakra-ui/react";
import bub from '../../../img/Ellipse 8.png';
import bob from '../../../img/Ellipse 9.png';

function Material({ handleClick, materials, currentMaterial }) {
  return (
    <Flex
      justifyContent="space-between"
      h="190px"
      w="150px"
      flexDirection="column"
      as="button"
      onClick={handleClick}
      cursor="pointer"
    >
      <Center position="relative" w="150px" h="150px">
        <Image position="absolute" src={bub} />
        <Image position="absolute" src={bob} />
        <Image
          src={materials[currentMaterial].image}
          w="93px"
          h="98px"
        />
      </Center>
      <Text
        bgColor='rgba(10, 16, 30, 1)'
        color="white"
        border="1px solid rgba(48, 194, 212, 1)"
        borderRadius="10px"
        h="28px"
        w="140px"
        textAlign="center"
        lineHeight="30px"
        fontFamily={'Open sans'}
        fontSize={'12px'}
        letterSpacing={'5%'}
        as='b'
      >
        {materials[currentMaterial].text}
      </Text>
    </Flex>
  );
}

export default Material;
