import logoWeb from '../logo3.svg';
import { Flex, Center, Image, Button, Box } from '@chakra-ui/react';
import React from 'react';
import './Header.css';
import styled from 'styled-components';
import image from '../../../img/HD-mb.png';
import { HamburgerIcon } from '@chakra-ui/icons';
import { CloseIcon } from '@chakra-ui/icons';
import { ScaleFade } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import button from '../../../img/connect.png';
import { Link, useNavigate } from 'react-router-dom';
function Header() {
  const handleClick = () => {
    window.open("https://docs.dislands.com/", "_blank", "noopener noreferrer");
  };

  const navigate = useNavigate();

  const handleButtonClick = (refName) => {
    navigate('/'); // Điều hướng về trang chủ
    navigate(`/?scrollTo=${refName}`);
  }

  const { isOpen, onToggle } = useDisclosure()
  
  
  const ButtonCenter = styled.button`
    width: 25%;
    background-color: unset;
    color: white;
    font-size: 12px;
    margin: 5px;
    
    
    &:hover {
      background-color: unset;
      color: #ee6c49;
    }
  `;

  const HandleonToggle = () => {
    onToggle(!isOpen);
  };
  return (
    <Box zIndex={1000} position={'fixed'} w={'100%'} h={'60px'}  >
      <Flex
        w={'100%'}
        h={'60px'} 
        bg={'#FFF6E4'}
        justifyContent={'space-between'}
        alignItems={'center'}
        boxShadow='dark-lg'
      >
        <Link to="/">
        <Image
          ml={'36px'}
          cursor={'pointer'}
          className="logoWeb"
          w={'64px'}
          src={logoWeb}
        />
        </Link>
        
        <HamburgerIcon  as={isOpen ? CloseIcon : HamburgerIcon}
        mr={'40px'} w={'17px'} onClick={HandleonToggle}/>
      </Flex>
      <ScaleFade initialScale={0.9} in={isOpen}  >
        <Flex  
        transition={'ease-in-out 0.3s all'}
        id='header-hidden'
        align={'center'}
        w={'100%'}
        h={'108px'}
        bg={'#1C375D'}
        bgSize={'100% 101%'}
        // flexDirection={'column'}
        justifyContent={'center'}
        // position={'hidden'}
      >
        <Image h={'100px'} w={'100%'} src={image} zIndex={1} position={'absolute'} blendMode={'multiply'}/>
        <Flex  zIndex={2} > 
          <ButtonCenter onClick={() => handleButtonClick('RefWork')}>
            FEATURES
          </ButtonCenter>
          <ButtonCenter onClick={() => handleButtonClick('RefROADMAP')}>
            ROADMAP
          </ButtonCenter>

          <ButtonCenter onClick={handleClick}>DOCUMENT</ButtonCenter>
          <ButtonCenter>ABOUT US</ButtonCenter>
        </Flex>
        {/* <Button
                position= {'absolute'}
                visibility={'hidden'}
                display={isOpen ? 'none' : 'block'}
          // display={{ sm: 'none', xl: 'block' }}
          
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 0px   rgba(0, 0, 0, 0.7)',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: ' inset 0 0px 0 0px   rgba(0, 0, 0, 0.7)',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
          colorScheme="black"
          borderRadius={'none'}
          color={'white'}
          fontWeight={'700'}
          fontSize={'10px'}
          textAlign={'center'}
          w={'130px'}
          ml={'30%'}
          h={'30px'}
          bgImage={button}
          marginBottom={'15px'}
          zIndex={2}
        >
          CONNECT WALLET{' '}

        </Button> */}
        </Flex>  
      </ScaleFade>  
  
      </Box>
  );
}

export default Header;
