
import { Flex, Text, Image, Center } from "@chakra-ui/react";
import bub from '../../../img/Ellipse 8.png';
import bob from '../../../img/Ellipse 9.png';

function Material({ handleClick, materials, currentMaterial }) {
  return (
    <Flex
      mt={'150px'}
      justifyContent="space-between"
      h="338px"
      w="232px"
      flexDirection="column"
      as="button"
      onClick={handleClick}
      cursor="pointer"
    >
      <Center position="relative" w="229px" h="229px">
        <Image position="absolute" src={bub} />
        <Image position="absolute" src={bob} />
        <Image
          src={materials[currentMaterial].image}
          w="166px"
          h="177px"
        />
      </Center>
      <Text
        bgColor='rgba(10, 16, 30, 1)'
        color="white"
        border="1px solid rgba(48, 194, 212, 1)"
        borderRadius="10px"
        h="40px"
        w="232px"
        textAlign="center"
        lineHeight="40px"
      >
        {materials[currentMaterial].text}
      </Text>
    </Flex>
  );
}

export default Material;
