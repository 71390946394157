import React, { useState } from 'react';
import { Flex, Center, Box, Image } from '@chakra-ui/react';
import Dynamic from '../../../img/Dynamic1 - Copy.png';
import DNM9 from '../../../img/DNM9 - Copy.png';
import w from '../../../img/Materials.png';
import iron from '../../../img/NL1.png';
import jewelry from '../../../img/NL2.png';
import bone from '../../../img/NL3.png';
import Season from './season';
import Material from './Material';
import SeasonLand from './SeasonLand';
import spring from '../../../img/Island.png';
import summer from '../../../img/dao1.png';
import autumn from '../../../img/image 28.png';
import winter from '../../../img/image 29.png';
import title from '../../../img/TitleDNM.png';
import '../GameList/GameList.css';

function DynamicWorld({ RefDynamicWorld }) {
  const [selectedMaterial, setSelectedMaterial] = useState(0);
  const [selectedSeason, setSelectedSeason] = useState(0);

  const materials = [
    { image: w, text: 'WOOD' },
    { image: iron, text: 'IRON' },
    { image: jewelry, text: 'JEWELRY' },
    { image: bone, text: 'BONE' },
  ];

  const seasons = [
    { image: spring, text: 'SPRING SEASON' },
    { image: summer, text: 'SUMMER SEASON' },
    { image: autumn, text: 'AUTUMN SEASON' },
    { image: winter, text: 'WINTER SEASON' },
  ];

  const handleNext = () => {
    const nextMaterial = (selectedMaterial + 1) % materials.length;
    const nextSeason = (selectedSeason + 1) % seasons.length;
    setSelectedMaterial(nextMaterial);
    setSelectedSeason(nextSeason);
  };

  const handleClick = index => {
    setSelectedSeason(index % seasons.length);
    setSelectedMaterial(index % materials.length);
  };

  return (
    <Center ref={RefDynamicWorld} w={'99%'} h={'1000px'} bg={'#FFF6E4'}>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'column'}
        w={'100%'}
        h={'900px'}
      >
        <Image src={title} />
        <Box
          className="content-container"
          w={'100%'}
          h={'753px'}
          backgroundRepeat={'no-repeat'}
          bgImage={Dynamic}
          bgSize={'100% 101%'}
          position={'relative'}
        >
          <Flex
          position={'absolute'}
          top={'-1px'}
            bgImage={DNM9}
            w={'101%'}
            h={'754px'}
            bgSize="100% 100%"
            bgPosition={'top'}
            justifyContent={'flex-start '}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Flex
              w={'100%'}
              h={'650px'}
              justifyContent={'space-evenly'}
              mt={'30px'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              <Season
                handleClick={handleClick}
                selectedSeason={selectedSeason}
              />
              <Material
                currentMaterial={selectedMaterial}
                materials={materials}
                handleClick={handleNext}
              />
              <SeasonLand
                currentSeason={selectedSeason}
                seasons={seasons}
                handleClick={handleNext}
              />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Center>
  );
}

export default DynamicWorld;
