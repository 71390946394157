import { Center, Image, Text, Button } from '@chakra-ui/react';
import dislandLogo from '../../../img/Disland_LogoDisland_Logo.png';
import line from '../../../img/Scratch line.png';
import bottom from '../../../img/Group 81imgLeft_bottom.png';
import button from '../../../img/Group 79bg_Button.png';
import { Link as RouterLink } from 'react-router-dom';

function Left() {
  // &:active {
  //   transform: translate(0.05em, 0.05em);
  //   box-shadow: 0.05em 0.05em rgb(53, 57, 53);
  return (
    <Center
      position={'relative'}
      display={'flex'}
      flexDirection={'column'}
      w={'343.9px'}
      h={'536.5px'}
    >
      <Image
        position={'absolute'}
        top={'-70px'}
        zIndex={'1'}
        w={'100%'}
        h={'191.05px'}
        src={dislandLogo}
      />
      <Center w={'326.19px'} h={'224.6px'} bgColor={'#186585'}>
        <Image mt={'25px'} w={'1px'} h={'186px'} src={line} />
        <Text
          mt={'60px'}
          mx={'10px'}
          w={'283.32px'}
          h={'121.16px'}
          fontSize={'16px'}
          fontWeight={'600'}
          fontFamily={'Open Sans'}
          textAlign={'center'}
          color={'#ffff'}
        >
          D’islands revolutionizes blockchain gaming with a dynamic metaverse
          filled with endless FOCG activities where entertainment and
          sustainability intersect.
        </Text>
        <Image mt={'25px'} w={'1px'} h={'186px'} src={line} />
      </Center>

      <Center w={'326.19px'} h={'136.52px'} bgSize={'cover'} bgImage={bottom} gap={'10px'}>
        <Button
          as={RouterLink}
          to='/CardKnight'
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
          mb={'50px'}
          colorScheme="black"
          borderRadius={'none'}
          color={'white'}
          fontWeight={'700'}
          fontSize={'18px'}
          lineHeight={'30px'}
          textAlign={'center'}
          w={'150px'}
          h={'59.65px'}
          bgImage={button}
        >
          CARD KNIGHT
        </Button>
        <Button
          as='a'
          href='https://t.me/dislands_hub_bot/'
          target='_blank'
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
          mb={'50px'}
          colorScheme="black"
          borderRadius={'none'}
          color={'white'}
          fontWeight={'700'}
          fontSize={'18px'}
          lineHeight={'30px'}
          textAlign={'center'}
          w={'150px'}
          h={'59.65px'}
          bgImage={button}
        >
         TELE APP
        </Button>
      </Center>
    </Center>
  );
}

export default Left;
