import { Box, Text, Flex, Image, Button, Center } from '@chakra-ui/react';
import tk from '../../img/Rectangle 111TicketBackgrong.png';
import tk1 from '../../img/Ticket1.png';
import tk2 from '../../img/Ticket2.png';
import button from '../../img/Group 79bg_Button.png';
import rec from '../../img/Rectangle 102recTicket.png'
import line from '../../img/Group 89lineTicket.png'
function Ticket({ RefTicket }) {

  return (
    <Center ref={RefTicket} w={'100%'} py={{ base: '5%', lg: '10%' }} bg={'#FFF6E4'}>
      <Flex
        w={'100%'}
        bgImage={tk}
        bgSize={'cover'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Image w={'10%'}  src={tk1}         ml={'4%'}
        />
        <Flex
          flexDirection={'column'}
          w={'45%'}
          fontSize={'30px'}
          textColor={'white'}
          alignItems={'center'}
          textAlign={'center'}
          justifyContent={'space-evenly'}
        >
          <Flex alignItems={'center'} flexDirection={'column'}>

          <Flex mb={{sm: '20px', md: '0'}} flexDirection={'column'} h={{sm: '50px', md: '65px'}} w={'441px'}  justifyContent={'space-between'} alignItems={'center'}>
          <Text color={'white'} lineHeight={'30px'} fontWeight={'700'} fontSize={{sm: '25px', md:'40px'}}> TICKET MECHANISM </Text>
            <Flex  justifyContent={'center'} alignItems={'center'} >
            <Image w={{sm: '270px' , md: '450px' }} position={'absolute'} src={line}/>
            <Image position={'absolute'} w={'20px'} h={'20px'} zIndex={'1.5'} src={rec} />
            </Flex>
        </Flex>

          <Text display={{sm: 'none', md: 'inline-block'}} fontWeight={'600'} fontFamily={'Open Sans'} fontSize={{ base: '15px', xl: '18px' }} my={'5%'}>
            Unique ticket mechanism ensuring a sustainable gaming ecosystem
          </Text>
          </Flex>
          <Button
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.75)',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'none',
          }}
          colorScheme="black"
          borderRadius={'none'}
          color={'white'}
          fontWeight={'700'}
          fontSize={{ sm: '14px' ,md: '16px'}}
          textAlign={'center'}
          w={{ sm: '150px' ,md:'189px'}}
          h={{ sm: '40px' ,md: '48px'}}
          bgSize={'cover'}
          bgImage={button}
        >
          JOIN NOW
        </Button>
        </Flex>
        <Image mt={'15%'} w={'15%'}  src={tk2} />
      </Flex>
    </Center>
  );
}

export default Ticket;
