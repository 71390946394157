import bg1 from '../../../img/ft1.png';
import { Flex, Center} from '@chakra-ui/react';
import Left from './Left';
import Right from './Right';

function HeroBanner({ RefBanner }) {

  return (
    <Center 
    ref={RefBanner}
    bgRepeat={'no-repeat'}>
    <Flex
      pt={'10%'}
     w={'100%'}
     backgroundImage={bg1}
     bgSize={'cover'}
      justifyContent={'flex-end'}
    >
      <Flex
        flexDirection={{sm: 'column', md: 'row' }}
        pt={'10%'}
        h={'90%'}
        w={'90%'}
        justifyContent={'space-between'}
        alignItems={{sm: 'center', md: 'flex-end'}}
      >
        <Left  />
        <Right />
      </Flex>
    </Flex>
    </Center>
  );
}

export default HeroBanner;
