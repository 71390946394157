import React, { useEffect } from 'react';
import { Center, Text, Box, Flex, Image, Grid, GridItem } from '@chakra-ui/react';
import Header from './headerAB';
import Footer from './footerAB';
import avt1 from '../../../img/avt1.png';
import avt2 from '../../../img/avt2.png';
import avt3 from '../../../img/avt3.png';
import avt4 from '../../../img/avt4.png';
import avt5 from '../../../img/avt5.png';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0); // Cuộn lên đầu trang khi component được tạo
  }, []); 

  return (
    <>
      <Header />
      <Center
        w={'100%'}
        h={'1300px'}
        p={4}
        minH={'100vh'}
        bg={'#FFF6E4'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
      >
        <Center
          w={{ base: '90%', md: '360px' }}
          h={'240px'}
          flexDirection={'column'}mt={'25%'}
        >
          <Box
            fontFamily={'Rowdies'}
            fontSize={{ base: '24px', md: '30px' }}
            fontWeight={'700'}
            lineHeight={'37px'}
            textColor={'#1C375D'}
            textAlign={'center'}
          >
            ABOUT US
          </Box>
          <Flex
            w={'100%'}
            h={'168px'}
            mt={'20px'}
            flexDirection={'column'}
            fontFamily={'Open sans'}
            fontSize={['12px', '14px', '16px']}
            fontWeight={'600'}
            lineHeight={'24px'}
            textColor={'#1C375D'}
            textAlign={'center'}
            as='b'
          >
            <Text>Our goal is to develop an open-world game</Text>
            <Text>with boundless possibilities that is free of cost</Text>
            <Text>and, above all, enjoyable. In our virtual world</Text>
            <Text>of D'islands, people from every corner of the</Text>
            <Text>globe can come together to explore, embark</Text>
            <Text>on adventures, and socialize without any</Text>
            <Text>barriers or limitations.</Text>
          </Flex>
        </Center>
        <Box
          w={{ base: '100%', md: '395px' }}
          mt={'20px'}
          gap={'12px'}
        >
          <Grid
            templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' }}
            gap={4}
          >
            <GridItem>
              <Image src={avt1} h={'234px'} />
              <Box
                fontFamily={'Rowdies'}
                fontSize={'18px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
                mt={'10px'}
              >
                ANDERSON
              </Box>
              <Box
                fontFamily={'Open sans'}
                fontSize={'16px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
              >
                Product Designer
              </Box>
            </GridItem>
            <GridItem>
              <Image src={avt2} h={'234px'} />
              <Box
                fontFamily={'Rowdies'}
                fontSize={'18px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
                mt={'10px'}
              >
                JOHN
              </Box>
              <Box
                fontFamily={'Open sans'}
                fontSize={'16px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
              >
                CEO
              </Box>
            </GridItem>
            <GridItem>
              <Image src={avt3} h={'234px'} />
              <Box
                fontFamily={'Rowdies'}
                fontSize={'18px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
                mt={'10px'}
              >
                RICK
              </Box>
              <Box
                fontFamily={'Open sans'}
                fontSize={'16px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
              >
                CTO
              </Box>
            </GridItem>
            <GridItem>
              <Image src={avt4} h={'234px'} />
              <Box
                fontFamily={'Rowdies'}
                fontSize={'18px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
                mt={'10px'}
              >
                KAYD
              </Box>
              <Box
                fontFamily={'Open sans'}
                fontSize={'16px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
              >
                CMO
              </Box>
            </GridItem>
          </Grid>
          <Center mt={'10px'} flexDirection={'column'}>
              <Image src={avt5} h={'234px'} />
              <Box
                fontFamily={'Rowdies'}
                fontSize={'18px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
                mt={'10px'}
              >
                JUSTIN
              </Box>
              <Box
                fontFamily={'Open sans'}
                fontSize={'16px'}
                fontWeight={'700'}
                lineHeight={'26px'}
                textAlign={'center'}
                textColor={'#1C375D'}
              >
                BRM
              </Box>
            </Center>
        </Box>
      </Center>
      <Footer />
    </>
  );
}

export default About;
