import { Text, Flex, Image, Center } from '@chakra-ui/react';
import tk from '../../img/bgTK.png';
import tk1 from '../../img/Ticket1.png';
import tk2 from '../../img/Ticket2.png';
import tk3 from '../../img/Ticket4.png';
import Title3 from '../../img/TitleTK.png';
import styled from 'styled-components';
import '../../component/Mobile/Header/Header.css';

const ButtonJoinNow = styled.button`
margin-top: 10px;
&:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em rgb(53, 57, 53);
}
`;

function Ticket({ RefTicket }) {

  return (
    <Center
      position={'relative'}
      bgImage={tk}
      bgRepeat={'repeat'}
      bgSize={'100% 101%'}
      ref={RefTicket}
      w={'99%'}
      h={'300px'}
    >
      <Flex
        w={'100%'}
        h={'195px'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Image w={'100%'} src={Title3} />
        <Flex
          w={'100%'}
          justifyContent={'center'}
          h={'150px'}
          alignItems={'center'}
        >
          <Image w={'70px'} marginBottom={'60px'}  src={tk1} />
          <Flex
            mt={'10px'}
            flexDirection={'column'}
            w={'80%'}
            h={'120px'}
            fontFamily={'Open sans'}
            fontSize={'30px'}
            textColor={'white'}
            alignItems={'center'}
            textAlign={'center'}
            justifyContent={'space-between'}
            ml={'25px'}
          >
            <Flex mt={'15px'} alignItems={'center'} flexDirection={'column'}>
              <Text fontSize={'14px'} fontFamily={'Open sans'}>
                Unique ticket mechanism ensuring a sustainable gaming ecosystem
              </Text>
            </Flex>
            <ButtonJoinNow>
              <Image src={tk3} h={'36px'} />
            </ButtonJoinNow>
          </Flex>
          <Image mt={'82px'} w={'87px'} src={tk2} />
        </Flex>
      </Flex>
    </Center>
  );
}

export default Ticket;
