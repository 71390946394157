import { Text, Box, Center, Flex, Image } from "@chakra-ui/react";
import road1 from '../../../img/Group 104Map.png'
import road2 from '../../../img/Group 105Map.png'


function ContainerRoadMap ({text1, text2, text3, text4, text5}) {

return(
    <Center position={'relative'} w={'228.14px'} h={'236.28px'}>
            <Image position={'absolute'} src={road1}  />
            <Image zIndex={'1'} position={'absolute'} src={road2}  />

        <Flex flexDirection={'column'}   border={'5px double #1C375D'} bgColor={'#FFF6E4'} w={'205px'} h={'211px'}>
           <Text  lineHeight={'18px'} fontSize={'15px'} fontWeight={'700'} fontFamily={'Open Sans'} p={'10px'} color={'#1C375D'} as='b'> {text1} </Text>
           <Text  lineHeight={'18px'} fontSize={'15px'} fontWeight={'700'} fontFamily={'Open Sans'} p={'10px'} color={'#1C375D'} as='b'> {text2} </Text>
           <Text  lineHeight={'18px'} fontSize={'15px'} fontWeight={'700'} fontFamily={'Open Sans'} p={'10px'} color={'#1C375D'} as='b'> {text3} </Text>
           <Text  lineHeight={'18px'} fontSize={'15px'} fontWeight={'700'} fontFamily={'Open Sans'} p={'10px'} color={'#1C375D'} as='b'> {text4} </Text>
           <Text  lineHeight={'18px'} fontSize={'15px'} fontWeight={'700'} fontFamily={'Open Sans'} p={'10px'} color={'#1C375D'} as='b'> {text5} </Text>
        </Flex>
        
    </Center>
);

}

export default ContainerRoadMap
