// import React from 'react';
// import { useState } from 'react';
// import {
//   Flex,
//   Center,
// } from '@chakra-ui/react';
// import Dynamic from '../../../img/Dynamic1.png';
// import DNM9 from '../../../img/DNM9.png';
// import w from '../../../img/Materials.png';
// import iron from '../../../img/NL1.png';
// import jewelry from '../../../img/NL2.png';
// import bone from '../../../img/NL3.png';
// import Season from './season';
// import Material from './Material';
// import SeasonLand from './SeasonLand';
// import spring from '../../../img/Island.png';
// import summer from '../../../img/dao1.png';
// import autumn from '../../../img/image 28.png';
// import winter from '../../../img/image 29.png';
// import Heading from '../Heading';

// function DynamicWorld({ RefDynamicWorld }) {

  
//   const [selectedIndex, setSelectedIndex] = useState(0);

//   const materials = [
//     { image: w, text: "MATERIAL" },
//     { image: iron, text: "IRON" },
//     { image: jewelry, text: "JEWELRY" },
//     { image: bone, text: "BONE" },
//   ];

//   const seasons = [
//     { image: spring, text: "SPRING SEASON" },
//     { image: summer, text: "SUMMER SEASON" },
//     { image: autumn, text: "AUTUMN SEASON" },
//     { image: winter, text: "WINTER SEASON" },
//   ];

//   const handleClick = () => {
//     const newIndex = (selectedIndex + 1) % (materials.length + seasons.length);
//     setSelectedIndex(newIndex);
//   };

//   return (
//     <Center ref={RefDynamicWorld} w={'100%'} bg={'#FFF6E4'}>
//       <Flex
//         justifyContent={'space-between'}
//         alignItems={'center'}
//         flexDirection={'column'}
//         w={'1287px'}
//         h={'767px'}
//       >
//         <Heading text={'DYNAMIC METAVERSE'} />
//         <Flex w={'1287px'} h={'671px'} bgImage={Dynamic} bgSize="cover">
//           <Flex
//             bgImage={DNM9}
//             w={'1287px'}
//             h={'671px'}
//             bgSize="cover"
//             justifyContent={'space-evenly'}
//             alignItems={'center'}
//           >
//             <Season               selectedIndex={selectedIndex} 
//  handleClick={handleClick} />

// <Material currentMaterial={selectedIndex % materials.length}
//               materials={materials}
//               handleClick={handleClick}/>

// <SeasonLand currentSeason={selectedIndex % seasons.length}
//               seasons={seasons}
//               handleClick={handleClick} />
            
//           </Flex>
//         </Flex>
//       </Flex>
//     </Center>
//   );
// }

// export default DynamicWorld;



// import React, { useState } from 'react';
// import { Flex, Center } from '@chakra-ui/react';
// import Dynamic from '../../../img/Dynamic1.png';
// import DNM9 from '../../../img/DNM9.png';
// import w from '../../../img/Materials.png';
// import iron from '../../../img/NL1.png';
// import jewelry from '../../../img/NL2.png';
// import bone from '../../../img/NL3.png';
// import Season from './Season';
// import Material from './Material';
// import SeasonLand from './SeasonLand';
// import spring from '../../../img/Island.png';
// import summer from '../../../img/dao1.png';
// import autumn from '../../../img/image 28.png';
// import winter from '../../../img/image 29.png';
// import Heading from '../Heading';

// function DynamicWorld({ RefDynamicWorld }) {
//   const [selectedMaterial, setSelectedMaterial] = useState(0);
//   const [selectedSeason, setSelectedSeason] = useState(0);

//   const materials = [
//     { image: w, text: "MATERIAL" },
//     { image: iron, text: "IRON" },
//     { image: jewelry, text: "JEWELRY" },
//     { image: bone, text: "BONE" },
//   ];

//   const seasons = [
//     { image: spring, text: "SPRING SEASON" },
//     { image: summer, text: "SUMMER SEASON" },
//     { image: autumn, text: "AUTUMN SEASON" },
//     { image: winter, text: "WINTER SEASON" },
//   ];

  // const handleClick = (index) => {
  //   setSelectedSeason(index % seasons.length);
  //   setSelectedMaterial(index % materials.length);
  // };

//   return (
//     <Center ref={RefDynamicWorld} w={'100%'} bg={'#FFF6E4'}>
//       <Flex
//         justifyContent={'space-between'}
//         alignItems={'center'}
//         flexDirection={'column'}
//         w={'1287px'}
//         h={'767px'}
//       >
//         <Heading text={'DYNAMIC METAVERSE'} />
//         <Flex w={'1287px'} h={'671px'} bgImage={Dynamic} bgSize="cover">
//           <Flex
//             bgImage={DNM9}
//             w={'1287px'}
//             h={'671px'}
//             bgSize="cover"
//             justifyContent={'space-evenly'}
//             alignItems={'center'}
//           >
//             <Season 
//               handleClick={handleClick} 
//               selectedSeason={selectedSeason} 
//             />
//             <Material 
//               currentMaterial={selectedMaterial}
//               materials={materials}
//               handleClick={handleClick}
//             />
//             <SeasonLand 
//               currentSeason={selectedSeason}
//               seasons={seasons}
//               handleClick={handleClick} 
//             />
//           </Flex>
//         </Flex>
//       </Flex>
//     </Center>
//   );
// }

// export default DynamicWorld;



import React, { useState } from 'react';
import { Flex, Center,Box } from '@chakra-ui/react';
import Dynamic from '../../../img/Dynamic1.png';
import DNM9 from '../../../img/DNM9.png';
import w from '../../../img/Materials.png';
import iron from '../../../img/NL1.png';
import jewelry from '../../../img/NL2.png';
import bone from '../../../img/NL3.png';
import Season from './season';
import Material from './Material';
import SeasonLand from './SeasonLand';
import spring from '../../../img/Island.png';
import summer from '../../../img/dao1.png';
import autumn from '../../../img/image 28.png';
import winter from '../../../img/image 29.png';
import Heading from '../Heading';
import '../GameList/GameList.css'

function DynamicWorld({ RefDynamicWorld }) {
  const [selectedMaterial, setSelectedMaterial] = useState(0);
  const [selectedSeason, setSelectedSeason] = useState(0);

  const materials = [
    { image: w, text: "WOOD" },
    { image: iron, text: "IRON" },
    { image: jewelry, text: "JEWELRY" },
    { image: bone, text: "BONE" },
  ];

  const seasons = [
    { image: spring, text: "SPRING SEASON" },
    { image: summer, text: "SUMMER SEASON" },
    { image: autumn, text: "AUTUMN SEASON" },
    { image: winter, text: "WINTER SEASON" },
  ];

  const handleNext = () => {
    const nextMaterial = (selectedMaterial + 1) % materials.length;
    const nextSeason = (selectedSeason + 1) % seasons.length;
    setSelectedMaterial(nextMaterial);
    setSelectedSeason(nextSeason);
  };

  const handleClick = (index) => {
    setSelectedSeason(index % seasons.length);
    setSelectedMaterial(index % materials.length);
  };

  return (
    <Center  ref={RefDynamicWorld} w={'100%'} bg={'#FFF6E4'}>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'column'}
        w={'1287px'}
        h={'767px'}
      >
        <Heading text={'DYNAMIC METAVERSE'} />
        <Box        
        className='content-container'
        justifyContent={'flex-start'}  
        overflowX={{sm:'scroll', xl:'hidden'}} w={'100%'}
        overflowY={'hidden'}
         h={'671px'} bgImage={Dynamic} bgSize="cover">
          <Flex
            bgImage={DNM9}
            w={'1287px'}
            h={'671px'}
            bgSize="cover"
            justifyContent={'space-evenly'}
            alignItems={'center'}
          >
            <Season
              handleClick={handleClick}
              selectedSeason={selectedSeason}
            />
            <Material
              currentMaterial={selectedMaterial}
              materials={materials}
              handleClick={handleNext}
            />
            <SeasonLand
              currentSeason={selectedSeason}
              seasons={seasons}
              handleClick={handleNext}
            />
          </Flex>
        </Box>
      </Flex>
    </Center>
  );
}

export default DynamicWorld;


{/* <Image
            as={'button'}
              onClick={() =>
                handleClick((currentImageIndex + 1) % images.length)
              }
              w={'232px'}
              mt={'338px'}
              src={images[currentImageIndex][0]}
            />
            <Button
              mr={'5%'}
              w={'35%'}
              bg={'unset'}
              _hover={{ bg: 'none' }}
              onClick={() =>
                handleClick((currentImageIndex + 1) % images.length)
              }
            >
              <Image w={'100%'} src={images[currentImageIndex][1]} />
            </Button> */}