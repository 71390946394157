import { Flex, Center, Image, Button, Box, Text } from '@chakra-ui/react';
import CTA1 from '../../../img/Rectangle 101.png';
import CTA2 from '../../../img/GL1.png';
import CTA3 from '../../../img/gl4.png';
import line1 from '../../../img/line1.png';
import Subtract2 from '../../../img/Subtract2.png';
import Subtract3 from '../../../img/Subtract3.png';
import './GameList.css';
import React, { useRef, useEffect } from 'react'; // Import useEffect
import Heading from '../Heading';
import Container from './Container';

function GameList({ RefGameList }) {
  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -containerRef.current.clientWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: containerRef.current.clientWidth,
        behavior: 'smooth',
      });
    }
  };



  return (
    <Center ref={RefGameList} w={'100%'} py={'50px'} bg={'#FFF6E4'}>
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        w={'100%'}
      >
        <Heading text={'EXPLORE OUR GAMES'} />

        <Text mt={'5px'} fontWeight={'600px'} fontSize={{ base: '12px', lg: '15px', xl: '18px' }}>
          Endless in-game activities and on-chain opportunities
        </Text>

        <Box
          ref={containerRef}
          display={'flex'}
          justifyContent={'flex-start'}
          className="content-container"
          overflowX={'scroll'}
          transition={'all 0.3s ease'}
          flexDirection={'row'}
          mt={'50px'}
          w={'97%'}
        >
          <Container bgImg={CTA1} textHeading={"D'ISLANDS ODYSSEY"} textBox={'RPG CARD GAME'} />
          <Container bgImg={CTA2} textHeading={"D'ISLANDS ARCADE"} textBox={'DYNAMIC METAVERSE'} />
          <Container opacity={'60%'} bgImg={CTA3} textHeading={"COMING SOON"} textBox={'COMING SOON'} />
        </Box>

        <Flex mt={'80px'} w={'100%'} justifyContent={'space-evenly'} alignItems={'center'}>
          <Image h={'1px'} w={'75%'} src={line1} />
          <Image
            // _active={{ transform: 'scale(1.2)' }}
            src={Subtract2}
            cursor={'pointer'}
            // onClick={scrollLeft}
            p={'20px'}
          />
          <Image
            // _active={{ transform: 'scale(1.2)' }}
            p={'20px'}
            cursor={'pointer'}
            // onClick={scrollRight}
            opacity={'30%'}
            src={Subtract3}
          />
        </Flex>
      </Flex>
    </Center>
  );
}

export default GameList;
