import button from '../../img/ButtonTuanPien.png';
import { Button } from '@chakra-ui/react';
function ButtonTuanPien({w, h, fontSize, lineHeight}) {

return(

    <Button
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'none',

            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
          colorScheme="none"
          borderRadius={'none'}
          color={'white'}
          fontWeight={'700'}
          fontSize={fontSize}
          lineHeight={lineHeight}
          textAlign={'center'}
          w={w}
          h={h}
          bgImage={button}
        >
          PLAY NOW
        </Button>

);

}

export default ButtonTuanPien