import { Box, Flex, Image, Text } from "@chakra-ui/react";
import layout from '../../../img/1.png';
import ButtonTuanPien from "../Button";

function Container({ bgImg, textHeading, textBox, text1, text2, opacity }) {
  return (
    <Flex opacity={opacity} ml={'50px'} flexDirection={'column'} w={'514px'} h={'460px'} gap={'21px'}>
      <Box w={'514px'} bgImg={bgImg} bgSize={'contain'}>
        <Image src={layout} />
      </Box>
      <Flex w={'514px'} h={'34px'} justifyContent={'space-between'} alignItems={'center'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Text color={'#1C375D'} lineHeight={'26px'} fontSize={'24px'} fontWeight={'700'}>
          {textHeading}
        </Text>
        <Flex flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} ml={'10px'}>
            <Text color={'#1C375D'} lineHeight={'10px'} fontSize={'10px'} fontWeight={'700'} as='s'>{text1}</Text>
            <Text color={'#1C375D'} lineHeight={'10px'} fontSize={'10px'} fontWeight={'700'}>{text2}</Text>
        </Flex>
        </Flex>
        <Text
          fontFamily={'Open Sans'}
          lineHeight={'26px'}
          fontSize={'12px'}
          color={'rgba(28, 55, 93, 0.5)'}
          borderColor="rgba(28, 55, 93, 0.3)"
          border={'1px solid'}
          px={'10px'}
          py={'5px'}
          as='b'
        >
          {textBox}
        </Text>
      </Flex>
      <ButtonTuanPien w={'160px'} h={'40px'} fontSize={'16px'} lineHeight={'30px'} />
    </Flex>
  );
}

export default Container;
