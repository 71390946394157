import bg1 from '../../../img/bgHR.png';
import '../../../component/Mobile/Header/Header.css';
import {
  Flex,
  Center,
  Image,
  Box,
} from '@chakra-ui/react';
import styled from 'styled-components';
import Left from './Left'
import Right from './Right'

const ButtonPlayNow = styled.button`
background: unset;
position: absolute;
z-index: 1;
top: 76.5%;
&:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em rgb(53, 57, 53);
}
`;

function HeroBanner({RefBanner}) {

  return (
    <Box
    position={'relative'}
      ref={RefBanner}
      backgroundImage={bg1}
      bgRepeat={'no-repeat'}
      bgPosition={'70% 50%'}
      bgSize={'cover'}
      h={'870px'}
      w={'99%'}
    >
      <Flex alignItems={'end'} h={'100%'} flexDirection={'column'} justifyContent={'center'} w={'100%'}>
        <Center mt={'30%'} w={'100%'}>
        <Left />
        </Center>
        <Right />
      </Flex>
    </Box>
  );
}

export default HeroBanner;



