import { Center, Image, Text, Box, Flex } from '@chakra-ui/react';
import contextLogo from '../../../img/Group 75bgRight.png';

function Right() {

  return (
    <Center display={'flex'} flexDirection={'column'} w={'580px'} h={'265.81px'} bgImg={contextLogo}>
        <Flex alignItems={'center'} flexDirection={'column'}>
        <Text textShadow='0px 4px 0px rgba(0, 0, 0, 0.25)' mb={'-30px'} as='em' color={'white'} fontSize={{sm: 
           '90px', md: '75px', lg:'90px'}}>
            NO ONE
        </Text>
        <Text textShadow='0px 4px 0px rgba(0, 0, 0, 0.25)' mb={'30px'} as='em' color={'white'} fontSize={{sm: 
           '55px', md: '45px', lg:'55px'}}>
            LEFT BEHIND
        </Text>
        </Flex>
    </Center>
  );
}

export default Right;


