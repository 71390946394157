import logoWeb from '../../../img/logo2.png';
import { Flex, Center, Image, Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import button from '../../../img/Group 79bg_Button.png';
import { Link , useNavigate } from 'react-router-dom';

const ButtonCenter = styled.button`
background-color: unset;
margin: 2%;
font-size: 25px;
color: #1c375d;
&:hover {
  background-color: unset;
  color: #ee6c49;
}
`;

function Header({ onButtonClick, RefBanner, RefWork, RefROADMAP }) {
  const navigate = useNavigate();

  const handleButtonClick = (refName) => {
    if (refName === 'about') {
      navigate('/about'); // Điều hướng đến /about khi click "ABOUT US"
    } else {
      onButtonClick(refName); // Xử lý các nút khác như cũ
    }
  };

  const handleClick = () => {
    window.open("https://docs.dislands.com/", "_blank", "noopener noreferrer");
  };

  return (
    <Center
      boxShadow="xl"
      zIndex={2}
      position={'fixed'}
      w={'100%'}
      bg={'#FFF6E4'}
    >
      <Flex
        w={'95%'}
        py={'10px'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Image
          cursor={'pointer'}
          className="logoWeb"
          w={{sm:'100px',md:'150px'}}
          src={logoWeb}
          onClick={() => onButtonClick(RefBanner)}

        />
        <Center marginLeft={'6%'} w={'80%'}>
          <Flex
            w={'100%'}
            zIndex={'1'}
            justifyContent={'center'}
            alignItems={'center'}
            display={{ sm: 'none', xl: 'flex' }}
          >
            <ButtonCenter
              className="ButtonCenter"
              onClick={() => onButtonClick(RefWork)}
            >
              FEATURES
            </ButtonCenter>
            <ButtonCenter
              className="ButtonCenter"
              onClick={() => onButtonClick(RefROADMAP)}
            >
              ROADMAP
            </ButtonCenter>

            <ButtonCenter className="ButtonCenter" onClick={handleClick}>DOCUMENT</ButtonCenter>
            <ButtonCenter className="ButtonCenter" onClick={() => handleButtonClick('about')}>
            ABOUT US
            </ButtonCenter>
          </Flex>
        </Center>

        <Button
        visibility={'hidden'}
          // display={{ sm: 'none', xl: 'hidden' }}
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 0px   rgba(0, 0, 0, 0.7)',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: ' inset 0 0px 0 0px   rgba(0, 0, 0, 0.7)',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
          colorScheme="black"
          borderRadius={'none'}
          color={'white'}
          fontWeight={'700'}
          fontSize={'15px'}
          textAlign={'center'}
          w={'18%'}
          h={'60px'}
          bgImage={button}
        >
          CONNECT WALLET{' '}
        </Button>
        <Menu>
          <MenuButton
            mb={'15px'}
            display={{ xl: 'none' }}
            className="hidden"
            _checked={{ bg: 'none' }}
            fontSize={'40px'}
            _hover={{ bg: 'none', color: 'orange' }}
            bg={'none'}
            as={IconButton}
            icon={<HamburgerIcon />}
          ></MenuButton>
          <MenuList>
            <MenuItem
              p={'10%'}
              fontSize={'20px'}
              _hover={{ color: 'orange' }}
              onClick={() => onButtonClick(RefWork)}
            >
              FEATURES
            </MenuItem>
            <MenuItem
              p={'10%'}
              fontSize={'20px'}
              _hover={{ color: 'orange' }}
              onClick={() => onButtonClick(RefROADMAP)}
            >
              ROADMAP
            </MenuItem>
            <MenuItem p={'10%'} fontSize={'20px'} _hover={{ color: 'orange' }} onClick={handleClick}>
              DOCUMENT
            </MenuItem>
            <MenuItem p={'10%'} fontSize={'20px'} _hover={{ color: 'orange' }}>
              <Link to="/about">ABOUT US</Link>
            </MenuItem>
            {/* <MenuItem p={'10%'} fontSize={'20px'} _hover={{ color: 'orange' }}>
              CONNECT WALLET
            </MenuItem> */}
          </MenuList>
        </Menu>
      </Flex>
    </Center>
  );
}

export default Header;
