import { Flex, Image, Box, Text } from '@chakra-ui/react';
import Tilte from '../../../img/TitleGL.png';
import CTA from '../../../img/CTA01 copy.png';
import CTA1 from '../../../img/gl001.png';
import CTA2 from '../../../img/gl002.png';
import CTA3 from '../../../img/gl003.png';
import React, { useRef, useEffect } from 'react';
import './GameList.css'

function GameList({ RefGameList }) {
  const gameItemRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isFullyVisible = entry.intersectionRatio === 1;
          entry.target.style.opacity = isFullyVisible ? 1 : entry.intersectionRatio;
        });
      },
      { threshold: [0, 0.5, 1] }
    );

    gameItemRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      gameItemRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const gameItems = [
    { imgSrc: CTA1, title: "D'ISLANDS ODYSSEY", genre: "RPG CARD GAME" },
    { imgSrc: CTA2, title: "D'ISLANDS ARCADE", genre: "DYNAMIC METAVERSE" },
    { imgSrc: CTA3, title: "COMING SOON", genre: "COMING SOON", opacity: '50%' },
  ];

  return (
    <Box ref={RefGameList} w={'100%'} h={'520px'} bg={'#FFF6E4'}>
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        w={'100%'}
        h={'100%'}
      >
        <Image src={Tilte} h={'96px'} />
        <Text fontSize={'16px'} fontFamily={'Open sans'} textColor={'#1C375D'} as='b' lineHeight={'24px'} mt={'10px'}>Endless activities in game and rewards</Text>
        <Flex
          display={'flex'}
          className="content-container"
          overflowX={'scroll'}
          overflowY={'hidden'}
          justifyContent={'start'}
          position={'relative'}
          transition={'all 0.3s ease'}
          flexDirection={'row'}
          mt={'50px'}
          w={'100%'}
        >
          {gameItems.map((item, index) => (
            <Box
              key={index}
              ref={(el) => (gameItemRefs.current[index] = el)}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              mx={'15px'}
              opacity={item.opacity}
            >
              <Image w={'185px'} h={'185px'} src={item.imgSrc} />
              <Flex alignItems={'center'}>
              <Text fontSize={'15px'} textColor={'#1C375D'} fontFamily={'Rowdies'}>{item.title}</Text>
              <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} ml={'10px'}>
                <Text fontSize={'6px'} textColor={'#1C375D'} fontFamily={'Rowdies'} as='s'>{item.text1}</Text>
                <Text fontSize={'6px'} textColor={'#1C375D'} fontFamily={'Rowdies'}>{item.text2}</Text>
              </Flex>
              </Flex>
              <Box
                fontFamily={'Open sans'}
                fontSize={'10px'}
                w={'182px'}
                h={'26px'}
                border="1px solid"
                borderColor="rgba(28, 55, 93, 0.3)"
                as='b'
                textAlign={'center'}
                mt={'10px'}
                lineHeight={'26px'}
              >
                <Text color="rgba(28, 55, 93, 0.5)">{item.genre}</Text>
              </Box>
              <Image cursor={'pointer'} h={'36px'} src={CTA} mt={'10px'} />
            </Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
}

export default GameList;
