import { Center, Image, Text, Box, Flex } from '@chakra-ui/react';
import contextLogo from '../../../img/Group 75bgRight.png';

function Right() {
  return (
    <Center
      display={'flex'}
      flexDirection={'column'}
      w={'80%'}
      h={'20%'}
      bgSize={'cover'}
      bgImg={contextLogo}
    >
      <Flex justifyContent={'center'} height={'100%'}  alignItems={'center'} flexDirection={'column'}>
        <Text
          textShadow="0px 4px 0px rgba(0, 0, 0, 0.25)"
          as="em"
          color={'white'}
          mt={'10px'}
          fontSize= {55}
        >
          NO ONE
        </Text>
        <Text
          textShadow="0px 4px 0px rgba(0, 0, 0, 0.25)"
          as="em"
          color={'white'}
          mb={'30px'}
          fontSize={33}
        >
          LEFT BEHIND
        </Text>
      </Flex>
    </Center>
  );
}

export default Right;
