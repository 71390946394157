import { Flex, Image, Button, Box, Text, Center } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import FTT1 from '../../../img/FTT1.png';
import FTT2 from '../../../img/Rectangle 100.png';
import logo2 from '../../../img/logo2.png';
import icon from '../../../img/icon.png';
import React from 'react';
import fb from '../../../img/cm1.png';
import dis from '../../../img/cm02.png';
import tele from '../../../img/cm03.png';
import twitter from '../../../img/cm04.png';
import '../../icon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareXTwitter,
  faDiscord,
  faGithub,
  faMedium,
} from '@fortawesome/free-brands-svg-icons';

function Footer({ onButtonClick, RefBanner, RefROADMAP, RefWork }) {
  const handleClick = () => {
    window.open('https://docs.dislands.com/', '_blank', 'noopener noreferrer');
  };
  const handleClickX = () => {
    window.open('https://x.com/0xDislands', '_blank', 'noopener noreferrer');
  };
  const handleClickDiscord = () => {
    window.open(
      'https://discord.gg/AZ7HEYy5nX',
      '_blank',
      'noopener noreferrer'
    );
  };
  const handleClickMedium = () => {
    window.open(
      'https://medium.com/@dislands',
      '_blank',
      'noopener noreferrer'
    );
  };
  const handleClickGithub = () => {
    window.open(
      'https://github.com/0xDislands/',
      '_blank',
      'noopener noreferrer'
    );
  };
  return (
    <Center
      position={'relative'}
      w={'100%'}
      h={'252px'}
      bgImage={FTT2}
      bgSize={'cover'}
    >
      <Image
        position={'absolute'}
        zIndex={''}
        bgColor={'rgba(24, 101, 133, 1)'}
        blendMode={'multiply'}
        w={'100%'}
        h={'252px'}
      />
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        position={'relative'}
        w={'90%'}
        h={'120px'}
      >
        <Button
          onClick={() => onButtonClick(RefBanner)}
          w={'150px'}
          right={'-5%'}
          top={'-72%'}
          bg={'unset'}
          _hover={{ bg: 'none' }}
          zIndex={1.5}
          position={'absolute'}
        >
          <Image cursor={'pointer'} w={'84.85px'} h={'84.45px'} src={FTT1} />
        </Button>
        <Image  cursor={'pointer'}          onClick={() => onButtonClick(RefBanner)}
 src={logo2} zIndex={3} w={'216px'} h={'120px'} />
        <Flex
          flexWrap={'wrap'}
          w={'348px'}
          h={'67px'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Flex
            cursor={'pointer'}
            w={'116.5px'}
            h={'30px'}
            bg={'unset'}
            variant={'link'}
            onClick={() => onButtonClick(RefWork)}
            alignItems={'center'}
            justifyContent={'space-between'}
            _hover={{ color: '#ee6c49' }}
          >
            <Image
              h={{ sm: '12px', md: '16px' }}
              w={{ sm: '12px', md: '16px' }}
              src={icon}
            />
            <Text
              fontSize={{ sm: '14px', md: '16px' }}
              lineHeight={'30px'}
              _hover={{ color: '#ee6c49' }}
              fontWeight={'700'}
              textColor={'#FFF6E4'}
            >
              FEATURES
            </Text>
          </Flex>
          <Flex
            cursor={'pointer'}
            w={'116.5px'}
            h={'30px'}
            bg={'unset'}
            variant={'link'}
            onClick={handleClick}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Image
              h={{ sm: '12px', md: '16px' }}
              w={{ sm: '12px', md: '16px' }}
              src={icon}
            />
            <Text
              _hover={{ color: '#ee6c49' }}
              ml={'10px'}
              fontSize={{ sm: '14px', md: '16px' }}
              lineHeight={'30px'}
              fontWeight={'700'}
              textColor={'#FFF6E4'}
            >
              DOCUMENTS
            </Text>
          </Flex>
          <Flex
            cursor={'pointer'}
            w={'116.5px'}
            h={'30px'}
            bg={'unset'}
            alignItems={'center'}
            justifyContent={'space-between'}
            onClick={() => onButtonClick(RefROADMAP)}
          >
            <Image
              h={{ sm: '12px', md: '16px' }}
              w={{ sm: '12px', md: '16px' }}
              src={icon}
            />
            <Text
              fontSize={{ sm: '14px', md: '16px' }}
              _hover={{ color: '#ee6c49' }}
              lineHeight={'30px'}
              fontWeight={'700'}
              textColor={'#FFF6E4'}
            >
              ROADMAP
            </Text>
          </Flex>
          <Flex
            cursor={'pointer'}
            w={'116.5px'}
            h={'30px'}
            bg={'unset'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Image
              h={{ sm: '12px', md: '16px' }}
              w={{ sm: '12px', md: '16px' }}
              src={icon}
            />
            <Text
              fontSize={{ sm: '14px', md: '16px' }}
              lineHeight={'30px'}
              _hover={{ color: '#ee6c49' }}
              fontWeight={'700'}
              textColor={'#FFF6E4'}
            >
              <Link to="/about">ABOUT US</Link>
            </Text>
          </Flex>
        </Flex>
        <Flex
          h={'87px'}
          w={'245px'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Text
            fontSize={'20px'}
            lineHeight={'26px'}
            textAlign={'center'}
            color={'white'}
            position={'relative'}
            zIndex={3}
          >
            JOIN OUR COMMUNITY
          </Text>
          <Center>
            <Flex
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              h={'44px'}
              w={{ sm: '110px', md: '233px' }}
            >
              <FontAwesomeIcon
                cursor={'pointer'}
                icon={faSquareXTwitter}
                fontSize={'2em'}
                onClick={handleClickX}
                color="white"
                className="icon"
              />
              <FontAwesomeIcon
                cursor={'pointer'}
                icon={faDiscord}
                fontSize={'2em'}
                onClick={handleClickDiscord}
                color="white"
                className="icon"
              />
              <FontAwesomeIcon
                cursor={'pointer'}
                icon={faGithub}
                fontSize={'2em'}
                onClick={handleClickGithub}
                color="white"
                className="icon"
              />
              <FontAwesomeIcon
                cursor={'pointer'}
                icon={faMedium}
                fontSize={'2em'}
                onClick={handleClickMedium}
                color="white"
                className="icon"
              />
            </Flex>
          </Center>
        </Flex>
      </Flex>
    </Center>
  );
}

export default Footer;
