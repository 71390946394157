import { Flex, Image, Text } from "@chakra-ui/react";
import line from '../../img/Group 89.png'
import rec from '../../img/Rectangle 102.png'
function Heading({text}) {

    return(
        <Flex position={'relative'} justifyContent={'center'} alignItems={'center'} p={'10px'}>
            <Image src={line}/>
            <Image position={'absolute'} w={'20px'} h={'20px'} zIndex={'1.5'} top={'80%'}  src={rec} />
            <Text position={'absolute'} color={'#1C375D'} lineHeight={'30px'} fontWeight={'700'} fontSize={{sm: '35px', md:'40px'}}> {text} </Text>
        </Flex>
    );
}

export default Heading