import { extendTheme } from '@chakra-ui/react'


const breakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1500px',
}

const theme = extendTheme({
  fonts: {
    heading: `'Rowdies', sans-serif`,
    body: `'Rowdies', sans-serif`,
    main: `'Rowdies', sans-serif`
  }
},
   breakpoints )




export default theme;


